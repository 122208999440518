<template>
    <div class="institutionsInWrapper">
        <div class="header">
        </div>
        <div class="institutionsIn">
            <div class="institutionsInItem institutionsInItem1">
                <div class="title">
                    教学资源合作机构入驻
                </div>
                <div class="desc">
                    <p>如果您有优质课程资源，可成为资源提供方，提供优质课程的同时，可获得收益</p>
                </div>

                <div class="item">
                    <div class="title-1">
                        入驻要求
                    </div>
                    <div class="content">
                        <div class="content-item">
                            <div class="circle">
                                <span>1</span>
                            </div>
                            <p>依法在中国境内登记，具有独立法人资格，具有规范的财务制度和管理制度，无重大违法、违规、失信等不良行为记录。符合教培中心相应职业技能培训规范中有关教学资源提供机构的标准要求。</p>
                        </div>
                        <div class="content-item">
                            <div class="circle">
                                <span>2</span>
                            </div>
                            <p>具备专业的课程开发师资及专家团队，拥有课程的自主知识产权或拥有产权方授予的销售权。</p>
                        </div>
                        <div class="content-item">
                            <div class="circle">
                                <span>3</span>
                            </div>
                            <p>自愿按照人力资源和社会保障部社会保障能力建设中心的职业技能培训规范和管理要求提供相应的教学资源和服务。</p>
                        </div>
                        <div class="content-item">
                            <div class="circle">
                                <span>4</span>
                            </div>
                            <p>家庭服务业（康养产业）相关国家职业技能标准的参标单位、社会培训评价组织优先考虑。</p>
                        </div>
                    </div>
                </div>

                <div class="footer" @click="handleClick(1)">
                    入驻申请
                </div>
            </div>

            <div class="institutionsInItem institutionsInItem2">
                <div class="title">
                    参培机构入驻
                    <div class="item_header">
                        <span>如果你的学员或员工需要获得优质培训服务，由此进入申请成为参培机构即可获得</span>
                    </div>
                </div>
                <div class="item item1">
                    <!-- <div class="item_header">
                        <span>如果你的学员或员工需要获得优质培训服务，由此进入申请成为参培机构即可获得</span>
                    </div> -->
                    <div class="title-1">
                        入驻要求
                    </div>
                    <div class="content">
                        <div class="content-item">
                            <div class="circle1">
                                <span>1</span>
                            </div>
                            <p>具有独立法人资格，具备教育部门或人社部门颁发的家庭服务业（康养产业）相关职业（工种）办学许可证或其社会团体法人登记证书、事业单位法人登记证书、工商登记的营业执照中规定的业务范围包含人才培养、培训、教育咨询、人力资源服务等内容。无重大违法、违规、失信等不良行为记录。符合教培中心相应职业技能培训规范中有关参培机构的标准要求。</p>
                        </div>
                        <div class="content-item">
                            <div class="circle1">
                                <span>2</span>
                            </div>
                            <p>具有能够承担相应培训任务的理论课或实操课师资、设施设备及场地资源。</p>
                        </div>
                        <div class="content-item">
                            <div class="circle1">
                                <span>3</span>
                            </div>
                            <p>具备较为完善的组织管理制度、财务制度，以及健全的教学管理制度，包括培训服务管理、培训学员管理、培训师资管理、培训档案管理等制度管理体系。</p>
                        </div>
                        <div class="content-item">
                            <div class="circle1">
                                <span>4</span>
                            </div>
                            <p>自愿接受人力资源和社会保障部社会保障能力建设中心对培训全过程的管理和监督。</p>
                        </div>
                        <div class="content-item">
                            <div class="circle1">
                                <span>5</span>
                            </div>
                            <p>家庭服务业（康养产业）相关国家职业技能标准的参标单位、社会培训评价组织优先考虑。</p>
                        </div>
                    </div>
                </div>

                <!-- <div class="item item2">
                    <div class="item_header">
                        <span>如果你的学员或员工需要获得优质培训服务，由此进入申请成为参培单位即可获得</span>
                    </div>
                    <div class="title-1">
                        入驻要求
                    </div>
                    <div class="content">
                        <div class="content-item">
                            <div class="circle">1</div>
                            <p>注册时间6个月以上，有优质线下实训渠道资源</p>
                        </div>
                        <div class="content-item">
                            <div class="circle">2</div>
                            <p>组织机构代码/营业执照/税务登记证等原件扫描件等材料</p>
                        </div>
                        <div class="content-item">
                            <div class="circle">3</div>
                            <p>企业实名认证</p>
                        </div>
                        <div class="content-item">
                            <div class="circle">4</div>
                            <p>法人身份信息</p>
                        </div>
                        <div class="content-item">
                            <div class="circle">5</div>
                            <p>师资信息</p>
                        </div>
                        <div class="content-item">
                            <div class="circle">6</div>
                            <p>入驻承诺书</p>
                        </div>
                    </div>
                </div> -->

                <div class="footer" @click="handleClick(2)">
                    入驻申请
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="con">
                <span>主办单位：人力资源和社会保障部社会保障能力建设中心</span>
                <span>京ICP备 13009107号-3</span>
                <span>京公网安备 11010502033661</span>
                <span>技术支持：伟东云教育集团</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'institutionsIn',
    data(){
        return{
        }
    },
    methods:{
        handleClick(institutionType){
            if(institutionType === 1){//1 资源供应商机构 2培训单位机构
                this.$router.push({
                    path:'/resource-settled'
                })
            }else{
                this.$router.push({
                    path:'/organ-settled'
                })
            }
        }
    }
}
</script>

<style lang="stylus" scoped>
@import '../asset/css/institutionsIn.styl'
</style>